import * as mixpanel from 'mixpanel-browser';
import { datadogRum } from '@datadog/browser-rum';
import { Site } from '@datadog/browser-core/cjs/domain/configuration/intakeSites';
import { datadogLogs } from '@datadog/browser-logs';
import { sendSentryError } from './sentry-setup';

interface ConnectionInfo {
  id: number;
  name: string;
  email: string;
  account: string;
  accountId: number;
  role: string;
  dashboardId: number;
  dashboardName: string;
}

let isMixpanelSet = false;
let isDatadogSet = false;

const mixpanelToken = process.env.MIXPANEL_TOKEN;
const datadogToken = process.env.DATADOG_CLIENT_TOKEN;
let currentConnection: ConnectionInfo | null = null;

export const initTracking = (connection: ConnectionInfo) => {
  if (!mixpanelToken) {
    sendSentryError({ error: 'Mixpanel Token is not set for environment.' });
  } else if (!isMixpanelSet) {
    isMixpanelSet = true;
    currentConnection = connection;
    mixpanel.init(mixpanelToken as string);
    mixpanel.identify('');
  }

  datadogRum.stopSession();

  if (!datadogToken) {
    sendSentryError({ error: 'Datadog Token is not set for environment.' });
  } else if (!isDatadogSet) {
    isDatadogSet = true;

    datadogRum.init({
      applicationId: process.env.DATADOG_APP_ID as string,
      clientToken: process.env.DATADOG_CLIENT_TOKEN as string,
      site: process.env.DATADOG_SITE as Site,
      service: 'superset',
      env: process.env.ENV_TITLE,
      // TODO: get script for grabbing npm-pkg version, see SE/LW
      // version: hx_version,
      allowedTracingUrls: [process.env.URL_PAGE as string],
      sessionSampleRate: 100,
      sessionReplaySampleRate: 100,
      traceSampleRate: 100,
      telemetrySampleRate: 0,
      defaultPrivacyLevel: 'mask',
      enablePrivacyForActionName: true,
      trackUserInteractions: true,
      trackViewsManually: true,
      trackResources: true,
      trackLongTasks: true,
      compressIntakeRequests: true,
      trackingConsent: 'granted',
    });

    datadogLogs.init({
      clientToken: process.env.DATADOG_CLIENT_TOKEN as string,
      site: process.env.DATADOG_SITE as Site,
      service: 'superset',
      // TODO: get script for grabbing npm-pkg version, see SE/LW
      // version: hx_version,
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      env: process.env.ENV_TITLE,
      trackingConsent: 'granted',
      telemetrySampleRate: 0,
    });

    datadogRum.startView({
      name: connection.dashboardName,
      service: 'superset',
      // version: hx_version,
      context: {
        dashboardName: connection.dashboardName,
        dashboardId: connection.dashboardId,
        userRole: connection.role,
      },
    });

    datadogRum.setUser({
      id: `${connection.id}`,
      name: connection.name,
      email: connection.email,
      account: connection?.account,
      accountId: connection?.accountId,
      role: connection.role,
      dashboardId: connection.dashboardId,
      dashboardName: connection.dashboardName,
    });
  }
};

export const reportError = (error: any, context: any = {}): void => {
  if (process.env.SENTRY_DSN) {
    sendSentryError(error, context);
  }
  if (isDatadogSet) {
    if (!error) {
      datadogLogs.logger.error(context.title || context.name, context);
    } else {
      datadogLogs.logger.error(error.name, context, error);
    }
  }
};

export const reportDebug = (
  message: string,
  context?: any,
  error?: Error,
): void => {
  if (isDatadogSet) {
    datadogLogs.logger.debug(message, context || {}, error);
  }
};

export const actionTrack = (eventId: string, eventDetails: any = {}): void => {
  if (isMixpanelSet) {
    const details = {
      ...eventDetails,
      dashboardId: currentConnection?.dashboardId,
      dashboardName: currentConnection?.dashboardName,
    };
    mixpanel.track(eventId, details);
  }

  if (isDatadogSet) {
    // remove filter values from eventDetails
    const validFilterKeys = [
      'dashboardId',
      'dashboardName',
      'label',
      'switchPosition',
      'chartName',
    ];
    const filteredEventDetails = validFilterKeys.reduce(
      (details: { [key: string]: any }, key) => {
        const nextDetails = { ...details };
        if (eventDetails[key]) {
          nextDetails[key] = eventDetails[key];
        }
        return nextDetails;
      },
      {},
    );
    datadogRum.addAction(eventId, filteredEventDetails);
  }
};

export const resetMixpanel = () => {
  mixpanel.reset();
};
